.line-l-r {  
  &::after {
    border-bottom: 1px solid #b5b5bd;
    content: "";
    display: block;
    top: -20px;
    position: relative;
  }

  > * {
    z-index: 1;
    position: relative;
    display: inline-block;
    background: #b5b5bd;
    border-radius: 50%;
    padding: 11px 10px;
  }
}

.fs-social {
  font-size: 13.5px !important;
}

.bg-white-login {
  background-color: rgba($color: #ffffff, $alpha: 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}
