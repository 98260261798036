@font-face {
  font-family: 'Clip';
  src: url('https://wedding.golfchairat.com/assets/fonts/Clip.woff2') format('woff2'),
    url('https://wedding.golfchairat.com/assets/fonts/Clip.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.sign {
  font-family: 'Clip';
  font-size: 4.2em;
  color: #ffe6ff;
  text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd, -0.2rem 0.1rem 1rem #ff65bd,
    0.2rem 0.1rem 1rem #ff65bd, 0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  animation: shine 2s forwards, flicker 3s infinite;
  &.bottom {
    margin-left: 150px;
    margin-top: 65px;
    font-size: 1.5em;
  }
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd, -0.2rem 0.1rem 1rem #ff65bd,
      0.2rem 0.1rem 1rem #ff65bd, 0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
  28%,
  33% {
    color: #ff65bd;
    text-shadow: none;
  }
  82%,
  97% {
    color: #ff2483;
    text-shadow: none;
  }
}

.flicker1 {
  animation: shine 0s forwards, blink 5s 0s infinite;
}

.flicker2 {
  animation: shine 0s forwards, blink 9s 0s infinite;
}

.flicker3 {
  animation: shine 0s forwards, blink 3s 0s infinite;
}

.flicker4 {
  animation: shine 0s forwards, blink 2s 0s infinite;
}

.flicker5 {
  animation: shine 0s forwards, blink 12s 0s infinite;
}

.flicker6 {
  animation: shine 0s forwards, blink 7s 0s infinite;
}

.flicker7 {
  animation: shine 0s forwards, blink 1s 0s infinite;
}

.flicker8 {
  animation: shine 0s forwards, blink 6s 0s infinite;
}

.flicker9 {
  animation: shine 0s forwards, blink 2s 0s infinite;
}

@keyframes shine {
  // 0% {
  //   color: #6b1839;
  //   text-shadow: none;
  // }
  0% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd, -0.2rem 0.1rem 1rem #ff65bd,
      0.2rem 0.1rem 1rem #ff65bd, 0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}
