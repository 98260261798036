@import url('https://fonts.googleapis.com/css?family=Kanit:400,500,600,700,800,900|Roboto:400,500,700,900&display=swap');

body,
html {
  font-size: 13.5px;
  background-color: #f0f2f5;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

a {
  color: unset;
}

a:hover {
  color: unset;
  text-decoration: none;
}

a:focus,
button:focus {
  outline: none !important;
}

.height-fullscreen {
  height: 100vh;
}

// --------------------------------------------------------------------------------
.text-orange {
  color: #ffb347;
}

.text-gd {
  background: linear-gradient(to right, #999a9c 0%, #f9f9f9 10%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gd-invert {
  background: linear-gradient(to left, #999a9c 0%, #f9f9f9 10%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// --------------------------------------------------------------------------------
.bg-white {
  background-color: #ffffff !important;
}

.bg-main {
  background-color: #b5b5bd;
}

.bg-gray {
  background-color: #f2f3f8;
}

.bg-orange {
  background-color: #ffb347;
}

.bg-card {
  background-image: linear-gradient(70deg, #262527 45%, #3b3c3f 60%, #262527 75%);
  background-image: -webkit-linear-gradient(70deg, #262527 45%, #3b3c3f 60%, #262527 75%);
  background-image: -moz-linear-gradient(70deg, #262527 45%, #3b3c3f 60%, #262527 75%);
  background-image: -o-linear-gradient(70deg, #262527 45%, #3b3c3f 60%, #262527 75%);
  width: 100%;
  height: 100%;
}

.bg-banner {
  height: 70vh;
  @media (min-width: 600px) {
    height: 90vh;
  }
  @media (min-width: 800px) {
    margin-top: -80px;
  }
}

.bg-chatbot {
  background: url('https://wedding.golfchairat.com/assets/images/gallery.jpg') no-repeat;
  height: 150px;
  background-size: cover;
  background-position: center;
}

// --------------------------------------------------------------------------------
.MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
  margin-bottom: 2px !important;
}

.MuiFormHelperText-contained {
  margin-top: 7px !important;
  margin-left: 3px !important;
  margin-right: 0 !important;
}
