.snow-bg {
  width: 100%;
  height: 70vh;
  position: absolute;
  background-image: url('https://wedding.golfchairat.com/assets/images/sakura-1-1.png'),
    url('https://wedding.golfchairat.com/assets/images/sakura-2.png');
  animation: snow 10s linear infinite;
}

@keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }
  50% {
    background-position: 500px 500px, 100px 200px, -100px 150px;
  }
  100% {
    background-position: 500px 1000px, 200px 400px, -100px 300px;
  }
}
