.showHeart {
  -webkit-animation: fadeOut 2s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation: fadeOut 2s;
  -moz-animation-fill-mode: forwards;
  animation: fadeOut 2s;
  animation-fill-mode: forwards;
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
